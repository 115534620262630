import React, { useState, useEffect } from 'react';
import { Box, Typography, LinearProgress, Paper } from '@mui/material';

const Skills = () => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 500);
        return () => clearTimeout(timer);
    }, []);

    const skillsData = [
        {name: 'Java', level: 85},
        {name: 'Maven/Hibernate/Rest/Gradle', level: 80},
        {name: 'Spring boot, JPA, Data', level: 70},
        {name: 'MySQL/PosgreSQL', level: 80},
        {name: 'kafka', level: 10},
        {name: 'Microservices', level: 70},
        {name: 'MongoDb', level: 50},
        {name: 'Git/GitHub/GitLab/Bamboo', level: 80},
        {name: 'Docker/Podman', level: 80},
        {name: 'CI/CD', level: 80},
        {name: 'AWS/Kubernetes', level: 5},
        {name: 'React/JavaScript/css', level: 20},
        {name: 'Flutter', level: 20},
        {name: 'Amplify BaaS', level: 10},

    ];
    const otherSkills = [
        {name: 'Jira', level: 80},
        {name: 'Scrum/Agile', level: 80},
        {name: 'Linux', level: 50},

    ];

    const experienceYears = [2, 4, 6, 8, 10];
    const mySkillsHeight = '50vh'; // Half of the viewport height for "My Skills"
    const otherSkillsHeight = '25vh'; // A quarter for "Other Skills"
    const languagesHeight = '25vh';
    return (
        <div className="container" style={{ maxHeight: 'calc(100vh - 80px)', overflowY: 'auto' }}>
            <Paper elevation={3} sx={{ mb: 4, p: 2, maxHeight: mySkillsHeight, overflowY: 'auto' }}>
                <Typography variant="h4" align="center" gutterBottom>
                    My Skills
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    {experienceYears.map((year) => (
                        <Typography key={year} variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            {year} years
                        </Typography>
                    ))}
                </Box>
                {skillsData.map((skill, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                            {skill.name}
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={loaded ? skill.level : 0}
                            sx={{
                                height: '10px',
                                borderRadius: '5px',
                                transition: 'width 5s ease-in-out',
                            }}
                        />
                    </Box>
                ))}
            </Paper>
            <Paper elevation={3} sx={{ mb: 4, p: 2, maxHeight: otherSkillsHeight, overflowY: 'auto' }}>
                <Typography variant="h4" align="center" gutterBottom>
                    Other Skills
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    {experienceYears.map((year) => (
                        <Typography key={year} variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            {year} years
                        </Typography>
                    ))}
                </Box>
                {otherSkills.map((skill, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                            {skill.name}
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={loaded ? skill.level : 0}
                            sx={{
                                height: '10px',
                                borderRadius: '5px',
                                transition: 'width 5s ease-in-out',
                            }}
                        />
                    </Box>
                ))}
            </Paper>
            <Paper elevation={3} sx={{ p: 2, maxHeight: languagesHeight, overflowY: 'auto' }}>
                <Typography variant="h4" align="center" gutterBottom>
                    Languages
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    English - Business fluent
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    German - Business fluent
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    Farsi - Native
                </Typography>
            </Paper>
        </div>
    );
};


export default Skills;
