import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Sidebar from './components/Sidebar';
import HomeContent from './components/HomeContent';
import Contact from './components/Contact';
import Skills from './components/Skills';
import Projects from './components/Projects';
import './App.css';

// Create a theme instance.
const theme = createTheme({
    typography: {
        fontFamily: 'Nova Round, cursive',
        h1: {
            fontFamily: 'Nova Round, cursive',
            fontWeight: 700,
        },
        h2: {
            fontFamily: 'Nova Round, cursive',
            fontWeight: 700,
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <div className="appContainer">
                    <div className="centeredContainer"> {/* Wrap both sidebar and content */}
                        <div className="sidebar">
                            <Sidebar />
                        </div>
                        <div className="mainContent">
                            <Routes>
                                <Route path="/" element={<HomeContent />} />
                                <Route path="/home" element={<HomeContent />} />
                                <Route path="/skills" element={<Skills />} />
                                <Route path="/contact" element={<Contact />} />
                                <Route path="/projects" element={<Projects />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;
