import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {Link} from 'react-router-dom';
import '../App.css';
import {ListItemIcon} from "@mui/material";
import contactIcon from '../icons/contact.png';
import skills from '../icons/skills.png';
import home from '../icons/homeIcon.png';
import projects from '../icons/projects.png';


function Sidebar() {
    return (
        <div className="sidebar">
            <div className="navigation">
                        <List component="nav" aria-label="mailbox folders">
                            <ListItem className="listItem" disablePadding>
                                <ListItemIcon>
                                    <img src={home} alt="Code Sample" style={{width: 24, height: 24}}/>
                                </ListItemIcon>
                                <ListItemButton component={Link} to="/home" className="button">
                                    <ListItemText primary="Home"/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem className="listItem" disablePadding>
                                <ListItemIcon>
                                    <img src={contactIcon} alt="Code Sample" style={{width: 24, height: 24}}/>
                                </ListItemIcon>
                                <ListItemButton component={Link} to="/contact" className="button">
                                    <ListItemText primary="Contact"/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem className="listItem" disablePadding>
                                <ListItemIcon>
                                    <img src={skills} alt="Code Sample" style={{width: 24, height: 24}}/>
                                </ListItemIcon>
                                <ListItemButton component={Link} to="/skills" className="button">
                                    <ListItemText primary="Skills"/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem className="listItem" disablePadding>
                                <ListItemIcon>
                                    <img src={projects} alt="Code Sample" style={{width: 24, height: 24}}/>
                                </ListItemIcon>
                                <ListItemButton component={Link} to="/projects" className="button">
                                    <ListItemText primary="Projects"/>
                                </ListItemButton>
                            </ListItem>
                        </List>
            </div>
        </div>
    );
}

export default Sidebar;
