import React, { useState } from 'react';
import { Grid, Card, CardContent, CardActions, Button, Modal, Typography, Paper, useTheme, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const Projects = () => {
    const [openProject, setOpenProject] = useState({ open: false, project: null });

    const handleClose = () => {
        setOpenProject({ open: false, project: null });
    };

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400, // This will be overridden if fullScreen is true due to the fullScreen prop in the Modal
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        outline: 'none', // Remove browser default focus outline
    };
    const projects = [
        {
            id: 1,
            title: 'University of Amsterdam project Timely',
            description: [
                'Developed a backend in Spring Boot and co-developing on a flutter app to get user locations.',
                'Developing the API to handover the data to a python System. Developing a module to send questionnaires according to the language of a user as an scheduled job.',
                'Trouble shooting and co-working on Kubernetes deployment.'
            ],
            role: 'Senior Java Developer'
        },
        {
            id: 2,
            title: 'Semdatex GmbH',
            description: [
                'Developing a Cross-Platform Application Ecosystem, including Spring Boot, Keycloak, Node.js, React, MongoDB, Flutter.',
                'Creating an API for different consumers and using Keycloak for authentication and authorization.',
                'Managing the CI/CD pipeline and deploying on AWS/EKS.',
                'Development Team lead and coordinator',
                'Introducing Agile in team'
            ],
            role: 'Senior Java Developer, Software Architect'
        },
        {
            id: 3,
            title: 'Institute of Quality assurance and transparency in healthcare (IQTIG)',
            description: [
                'Involved in developing and enhancing a quality indicator database using Vaadin and Wildfly.',
                'Initiated and evaluated the first report generating pipeline using Docx4J and Jasper.',
                'Responsible for setting up deployment for website and report projects via Docker.',
                'Handled application deployment on Bamboo CI.'
            ],
            role: 'Java Developer'
        }
    ];

    return (
        <div className="container">
            <Grid container spacing={2}>
                {projects.map((project) => (
                    <Grid item xs={12} sm={6} md={4} key={project.id}>
                        <Card>
                            <CardContent>
                                {project.title}
                            </CardContent>
                            <CardActions>
                                <Button onClick={() => setOpenProject({ open: true, project })}>
                                    <AddIcon />
                                    Details
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            {openProject.project && (
                <Modal
                    open={openProject.open}
                    onClose={handleClose}
                    fullScreen={fullScreen}
                >
                    <Paper style={{ ...modalStyle, width: fullScreen ? 'auto' : 400 }}>
                        <Typography variant="h6" gutterBottom>
                            {openProject.project.role}
                        </Typography>
                        {openProject.project.description.map((point, index) => (
                            <Typography key={index} variant="body1" component="div" gutterBottom>
                                <li>{point}</li>
                            </Typography>
                        ))}
                        <Button onClick={handleClose} style={{ marginTop: '16px' }}>
                            Close
                        </Button>
                    </Paper>
                </Modal>
            )}
        </div>
    );
}

export default Projects;
