import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import '../App.css';

function HomeContent() {
    return (
        <div className="container" style={{ padding: '20px', maxWidth: '100vw', boxSizing: 'border-box' }}>
            <div style={{ paddingBottom: '20px' }}>
                <Paper elevation={3} sx={{
                    width: { xs: '100%', sm: '60%' }, // Full width on xs devices, 60% on sm and above
                    margin: 'auto',
                    borderRadius: '20px',
                    overflow: 'hidden', // Prevents content from spilling out
                }}>
                    <Typography variant="h4" gutterBottom sx={{ fontWeight: 600, padding: '20px', textAlign: 'center' }}>
                        Iman Gharib
                    </Typography>
                    <Typography variant="h5" sx={{
                        fontWeight: 'normal',
                        padding: '0 20px 20px',
                        textAlign: 'center',
                        textJustify: 'inter-word'
                    }}>
                        Senior Backend Developer <br />&<br />Software Architect<br />
                        <span style={{ fontSize: '1rem', fontWeight: 'normal' }}>
                            <i sx={{ textAlign: 'justify' }}>As a seasoned Backend Developer, I also have substantial experience with Flutter and React.
                                My role extends beyond coding to analyzing projects and engaging with stakeholders
                                to make critical decisions throughout the project's lifecycle.</i>
                        </span>
                    </Typography>
                </Paper>

                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, padding: '20px', flexWrap: 'wrap' }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'white',
                            fontWeight: 'bold',
                            borderRadius: '20px',
                            padding: '12px 24px', // Smaller padding for a more compact button
                            fontSize: { xs: '0.75rem', sm: '1rem' }, // Smaller font size on xs devices
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                                transform: 'scale(1.05)'
                            },
                            textTransform: 'none',
                        }}
                        component="a"
                        href={`${process.env.PUBLIC_URL}/assets/CV-ImanGharib.pdf`}
                        download
                    >
                        Download Resume
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'white',
                            fontWeight: 'bold',
                            borderRadius: '20px',
                            padding: '12px 24px', // Smaller padding for a more compact button
                            fontSize: { xs: '0.75rem', sm: '1rem' }, // Adjust font size for small devices
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                                transform: 'scale(1.05)'
                            },
                            textTransform: 'none',
                        }}
                        component="a"
                        href={`${process.env.PUBLIC_URL}/assets/certificates-ImanGharib.pdf`}
                        download
                    >
                        Download Certificates
                    </Button>
                </Box>
            </div>
        </div>
    );
}

export default HomeContent;
