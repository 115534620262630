import React, { Component } from 'react';
import {
    Card,
    CardContent,
    Typography,
    IconButton,
    Tooltip,
    Snackbar,
    Box,
    Paper
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class Contact extends Component {
    state = {
        snackbarOpen: false,
        snackbarMessage: '',
    };

    handleCopy = (text) => {
        this.setState({
            snackbarOpen: true,
            snackbarMessage: `Copied: ${text}`
        });
    };

    handleCloseSnackbar = () => {
        this.setState({
            snackbarOpen: false
        });
    };

    render() {
        const { snackbarOpen, snackbarMessage } = this.state;

        return (
            <div className="container" style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                padding: '20px',
                boxSizing: 'border-box',
                overflowY: 'unset'
            }}>
                <Paper elevation={3} sx={{
                    width: { xs: '100%', sm: '60%' }, // Responsive width
                    margin: 'auto',
                    borderRadius: '20px',
                    overflow: 'hidden'
                }}>
                    <Typography variant="h4" gutterBottom sx={{ fontWeight: 600, padding: '20px', textAlign: 'center' }}>
                        Contact Me
                    </Typography>
                </Paper>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column', // Stack vertically on all sizes for better mobile experience
                    alignItems: 'center',
                    padding: '20px',
                    width: '100%', // Full width to accommodate smaller screens
                    maxWidth: '500px', // Max width to maintain layout on larger screens
                    margin: '20px 0'
                }}>
                    <Card sx={{
                        borderRadius: '20px',
                        boxShadow: 3,
                        textAlign: 'center',
                        width: '100%', // Card takes full width of the container
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: 2,
                        padding: '20px'
                    }}>
                        <CardContent sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                        }}>
                            <InformationBox icon={<LocationOnIcon color="secondary" />} text="Berlin" />
                            <InformationBox icon={<PhoneIcon color="secondary" />} text="0049 176 66847457" handleCopy={this.handleCopy} />
                            <InformationBox icon={<EmailIcon color="secondary" />} text="igharib@ittrustsolution.com" handleCopy={this.handleCopy} />
                        </CardContent>
                    </Card>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={this.handleCloseSnackbar}
                        message={snackbarMessage}
                        action={
                            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseSnackbar}>
                                <ContentCopyIcon fontSize="small"/>
                            </IconButton>
                        }
                    />
                </Box>
            </div>
        );
    }
}

// A functional component for displaying contact information items consistently
function InformationBox({ icon, text, handleCopy }) {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            {icon}
            <Typography variant="body1" sx={{ marginRight: 1 }}>{text}</Typography>
            {handleCopy && (
                <CopyToClipboard text={text} onCopy={() => handleCopy(text)}>
                    <Tooltip title="Copy to clipboard">
                        <IconButton>
                            <ContentCopyIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </CopyToClipboard>
            )}
        </Box>
    );
}

export default Contact;
